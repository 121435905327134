<template>
  <v-app-bar app color="#273142">
    <v-toolbar-title style="cursor: pointer" class="" v-if="isAuthComponent" @click="$router.push('/')">
      <v-img height="50" width="270" aspect-ratio="1" :src="require('@/assets/img/Genius.png')" alt="Essay Genius" />
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-icon v-if="!isAuthComponent"
      >mdi-badge-account-horizontal-outline</v-icon
    >
    <v-btn text class="ml-5" v-if="!isAuthComponent" @click="$router.push('/dashboard/finance/transaction')">${{ getRunningBalance }}</v-btn>
    <v-icon class="ml-5" @click.native="passChat" v-if="!isAuthComponent"
      >mdi-chat</v-icon
    >

    <div class="ml-5" v-if="!isAuthComponent" @click="$router.push('/dashboard/notifications/')" >
      <v-badge
      :content="getNotification"
      :value="getNotification"
      >
        <v-icon>mdi-bell</v-icon>
      </v-badge>
    </div>
    
    <v-avatar class="ml-5 mr-5" v-if="!isAuthComponent">
      <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
    </v-avatar>

      <v-btn to="/auth/login" rounded  class="my-3 px-5" color="#273142" v-if="isAuthComponent">
        Login
      </v-btn>
      <v-btn to="/auth/register" rounded  class="ml-3 my-3 px-5" :color="essayOrange" v-if="isAuthComponent">
        Signup
      </v-btn>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters} from "vuex";
import colorMxn from '@/mixins/colorMxn';

export default {
  name: "topBar",
  mixins: [colorMxn],
  data() {
    return {
      notification: 0,
    };
  },
  computed: {
    ...mapGetters(['getSession', 'getChatStatus', 'getNotification', 'getRunningBalance']),
    isAuthComponent() {
      const res =
        this.$route.matched[0].path === "/auth" ||
        this.$route.name === "login_home";
      return res;
    },
  },
  methods: {
    ...mapMutations(['setChatStatus']),
    passChat() {
      const data = {
        status: !this.getChatStatus.status,
        room: null
      };
      this.setChatStatus(data);
    },
  }
};
</script>
